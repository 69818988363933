import React from 'react'
// import { Row, Col } from 'antd'
import { Container, Row, Button } from 'reactstrap'

import AudioPlayerH5 from '../../../AudioPlayer/H5player'
import AudioPlayer from '../../../AudioPlayer'
import Recorder from '../../../Recorder'
import Listen from '../../../Listening'



const P4E1 = ({ question }) => {
  const [state, setState] = React.useState({
    data: [],
    audioUrl: '',

    isPause: false
  })
  // const audioRef = React.useRef(new Audio(state.audioUrl))
  React.useEffect(() => {
    const dataClone = JSON.parse(JSON.stringify(question))
    setState((prevState) => ({ ...prevState, data: dataClone }))
  }, [question])

  const onCompleteExcercise = React.useCallback((audioUrl, imageUrl, indexData, indexImg, isLongAudio) => {
    if (isLongAudio) {
      setState((prevState) => ({ ...prevState, longAudioUrl: audioUrl }))
    } else {
      const isPause = audioUrl === state.audioUrl ? state.isPause ? false : true : false
      const newImageUrl = imageUrl.replace('-empty', '');
      state.data[indexData].imgUrl[indexImg] = newImageUrl
      // const newClassName = `${styles.img} animated pulse infinite`s

      setState((prevState) => ({
        ...prevState,
        audioUrl,
        data: state.data,
        isPause
        // className:newClassName
      }))
    }

  }, [state.data, state.audioUrl, state.isPause])

  const renderImg = React.useCallback(
    (data, indexData) => {
      return data.imgUrl.map((item, index) => {
        const isLongAudio = data.isLongAudio[index]
        let className = "";
        const volumeCursor = `url(img/volume.png) 12 12, auto`;
        const isIncludeAudio = data.audioUrl[index] ? true : false;
        if (data.audioUrl[index] && state.audioUrl === data.audioUrl[index] && !state.isPause) {
          className = `animated pulse infinite`;
        }
        return (
          <>
            { index !== 0 && (
              <img
                onClick={() =>
                  onCompleteExcercise(data.audioUrl[index], item, indexData, index, isLongAudio)
                }
                className={className}
                style={{ cursor: isIncludeAudio ? volumeCursor : "", width: '50%' }}
                src={item}
                alt="..."
              />
            )}
          </>
        );
      });
    },
    [onCompleteExcercise, state.audioUrl, state.isPause]
  );

  const renderContent = React.useCallback(() => {
    return state.data.map((item, index) => {
      return (
        <div key={index}>
          {renderImg(item, index)}
        </div>
      )
    })
  }, [renderImg, state.data])

  const onStopRecording = React.useCallback((recordURL) => {
    setState((prevState) => ({ ...prevState, recordURL: recordURL.blobURL }));
  }, []);

  if (!state.data) return null

  return (
    <Container className='fluid'>
      <div style={{ marginBottom: 25 }}>
        <img src={question[0].imgUrl[0]} alt="" style={{ width: '60%' }} />
      </div>
      <Row style={{ minWidth: 905, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {renderContent()}
      </Row>
      <Row className='d-flex justify-content-center mt-2 mb-2'>
        <AudioPlayer
          isPause={state.isPause}
          // isVisibled={isVisibled}
          audioUrl={state.audioUrl}
        // onAudioPlay={onAudioPlay}
        // onAudioPause={onAudioPause}
        />
        <AudioPlayerH5 audioUrl={state.longAudioUrl} />
        <span className='ml-4 mr-2'>
          <Recorder
            // onRecording={onRecording}
            onStop={onStopRecording}
          />
        </span>
        {state.recordURL && (
          <span className='ml-2 mr-2'>
            <Listen
              custom
              audioURL={state.recordURL}
              style={{ padding: 0, borderWidth: 0 }}
            >
              <Button color="primary" id="tooltipRepeat" type="button">
                <i style={{ fontSize: 15 }} className="fas fa-volume-up" />
              </Button>
            </Listen>
          </span>
        )}
      </Row>
    </Container>
  )
}
export default P4E1