import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Culture 2',
    id: 'WB2-C-P53-E1',
    audio: '',
    video: '',
    component: D1,
    inputHeight: 40,
    fontSize: 26,
    exerciseKey: 'img/FriendsPlus/Page53/E1/Key/1.PNG',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page53/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/3.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page53/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/5.jpg', input: true, answer: 'y' },
        { url: 'img/FriendsPlus/Page53/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/7.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page53/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/9.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page53/E1/10.jpg', input: true, answer: 'y' },
        { url: 'img/FriendsPlus/Page53/E1/11.jpg', },

      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/14.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page53/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/16.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page53/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/18.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page53/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/20.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page53/E1/21.jpg', },
        { url: 'img/FriendsPlus/Page53/E1/22.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page53/E1/23.jpg', },


      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/26.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page53/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/28.jpg', input: true, answer: 'w' },
        { url: 'img/FriendsPlus/Page53/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/30.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page53/E1/31.jpg', },
        { url: 'img/FriendsPlus/Page53/E1/32.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page53/E1/33.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page53/E1/34.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Culture 2',
    id: 'WB2-C-P53-E2',
    audio: '',
    video: '',
    component: D1,
    // exerciseKey: '',
    // recorder: true,
    isAllowSubmit: true,
    hideBtnFooter:true,
    // isLargeInput: true,
    fontSize: 30,
    // exerciseKey: 'img/FriendsPlus/Page53/E1/Key/answerKeyP53E1.jpg',
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page53/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page53/E2/3.jpg', input: true, },
        { url: 'img/FriendsPlus/Page53/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page53/E2/7.jpg', input: true, },
        { url: 'img/FriendsPlus/Page53/E2/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page53/E2/11.jpg', input: true, },
        { url: 'img/FriendsPlus/Page53/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page53/E2/15.jpg', input: true, },
        { url: 'img/FriendsPlus/Page53/E2/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page53/E2/19.jpg', input: true, },
        { url: 'img/FriendsPlus/Page53/E2/20.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page53/E2/21.jpg' },
      ],

    ]
  }
}
export default json;