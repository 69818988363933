
import L3 from '../../components/ExcerciseTypes/Design/Listen3'
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';


const json = {

  1: { // Exercise num
    unit : 'Unit 3',
    id :  'WB2-U3-P22-E1',
    // audio: 'img/FriendsPlus/Page22/E1/Audio/sing.e1.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page22/E1/Key/Key.png',
    recorder: true,
    toAnchor: '5% 50%',
    fromAnchor: '80% 50%',
    titleImage: 'img/FriendsPlus/Page22/E1/1.jpg',
    questionImage: [ 
      // [
      //   { url: 'img/FriendsPlus/Page22/E1/1.jpg' },
      // ],
      [
        { url: 'img/FriendsPlus/Page22/E1/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page22/E1/5.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page22/E1/8.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page22/E1/11.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page22/E1/14.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page22/E1/17.jpg', isMatching: true, id: 6 },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page22/E1/4.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page22/E1/7.jpg', isMatching: true, id: 8 },
        { url: 'img/FriendsPlus/Page22/E1/10.jpg', isMatching: true, id: 9 },
        { url: 'img/FriendsPlus/Page22/E1/13.jpg', isMatching: true, id: 10 },
        { url: 'img/FriendsPlus/Page22/E1/16.jpg', isMatching: true, id: 11 },
        { url: 'img/FriendsPlus/Page22/E1/19.jpg', isMatching: true, id: 12 },
      ],
    ],
    answers: ['1-11', '2-10', '3-12','4-7','5-8','6-9'],
  },

  2: { // Exercise num
    unit : 'Unit 3',
    id :  'WB2-U3-P22-E2',
    audio: '',
    video: '',
    component: L3,
    titleImage: 'img/FriendsPlus/Page22/E2/title.jpg',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page22/E2/title.jpg',
          'img/FriendsPlus/Page22/E2/1.jpg',
          'img/FriendsPlus/Page22/E2/2.jpg',
          'img/FriendsPlus/Page22/E2/3.jpg',
          'img/FriendsPlus/Page22/E2/4.jpg',
          'img/FriendsPlus/Page22/E2/5.jpg',
          'img/FriendsPlus/Page22/E2/6.jpg',

        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page22/E2/Audio/1.mp3',
          'img/FriendsPlus/Page22/E2/Audio/2.mp3',
          'img/FriendsPlus/Page22/E2/Audio/3.mp3',
          'img/FriendsPlus/Page22/E2/Audio/4.mp3',
          'img/FriendsPlus/Page22/E2/Audio/5.mp3',
          'img/FriendsPlus/Page22/E2/Audio/6.mp3',
          // 'img/FriendsPlus/Page22/E2/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;