
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import LT2_1 from '../../components/ExcerciseTypes/LineTo/LT2_1';

const json = {
    1: { // Exercise num
       unit : 'Unit 6',
      id :  'WB2-U6-P42-E1',
        audio: 'img/FriendsPlus/Page42/E1/Audio/audio.e1.p42.mp3',
        video: '',
        component: LT2_1,
        exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKeyP42E1.jpg',
        // recorder: true,
        toAnchor:'50% 50%',
        fromAnchor:'30% 50%',
        // titleImage: 'img/FriendsPlus/Page4/E1/1.jpg',
        questionImage: [ // Row
          [
            // Column1
            { url: 'img/FriendsPlus/Page42/E1/1.jpg'},
            { url: 'img/FriendsPlus/Page42/E1/2.jpg',audioUrl:'img/FriendsPlus/Page42/E1/Audio/tieude.e1.p42.mp3' },
            { url: 'img/FriendsPlus/Page42/E1/2_1.jpg', },
            { url: 'img/FriendsPlus/Page42/E1/3_1.jpg', isMatching: true, id: 1},
          ],
          [
            { url: 'img/FriendsPlus/Page42/E1/4.jpg'},
          ],
          [
            // Column2
            { url: 'img/FriendsPlus/Page42/E1/5.jpg'},
            { url: 'img/FriendsPlus/Page42/E1/6.jpg',},
            { url: 'img/FriendsPlus/Page42/E1/7.jpg', },
            { url: 'img/FriendsPlus/Page42/E1/8.jpg',  },
            { url: 'img/FriendsPlus/Page42/E1/9.jpg', isMatching: true, id: 7  },
            { url: 'img/FriendsPlus/Page42/E1/10.jpg', },
          ],
       
          [
            // Column4
           
            { url: 'img/FriendsPlus/Page42/E1/11.jpg'},
            { url: 'img/FriendsPlus/Page42/E1/12.jpg',   },
            { url: 'img/FriendsPlus/Page42/E1/13.jpg',  isMatching: true, id: 9 },
            { url: 'img/FriendsPlus/Page42/E1/14.jpg', },
            { url: 'img/FriendsPlus/Page42/E1/15.jpg',  isMatching: true, id: 11 },
            { url: 'img/FriendsPlus/Page42/E1/16.jpg'},
           
          ],
          [
            { url: 'img/FriendsPlus/Page42/E1/17.jpg'},
            { url: 'img/FriendsPlus/Page42/E1/18.jpg', isMatching: true, id: 2},
            { url: 'img/FriendsPlus/Page42/E1/19.jpg'},
            { url: 'img/FriendsPlus/Page42/E1/20.jpg', isMatching: true, id: 3},
            
          ]
          
        ],
        answers: ['1-11', '2-9','3-7',],
      },


  
    2: { // Exercise num
       unit : 'Unit 6',
      id :  'WB2-U6-P42-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 27,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page42/E2/Key/Key.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page42/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page42/E2/3.jpg', input: true, answer: "bedroom" },
                { url: 'img/FriendsPlus/Page42/E2/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page42/E2/7.jpg', input: true, answer: "kitchen" },
                { url: 'img/FriendsPlus/Page42/E2/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page42/E2/11.jpg', input: true, answer: "living room" },
                { url: 'img/FriendsPlus/Page42/E2/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/13.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page42/E2/15.jpg', input: true, answer: "bathroom" },
                { url: 'img/FriendsPlus/Page42/E2/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page42/E2/17.jpg' },
            ],
        ],
    },

}

export default json;