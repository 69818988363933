
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P6-E1',
    audio: '',
    video: '',
    inputHeight: 33,
    fontSize: 33,
    exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
    component: D1,
    //titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page6/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page6/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/3.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page6/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/5.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page6/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/7.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page6/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/9.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page6/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/11.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page6/E1/12.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page6/E1/13.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P6-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page6/E2/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page6/E2/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E2/07.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/08.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page6/E2/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page6/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/13.jpg', input: 2 },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/15.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page6/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/17.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E2/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/20.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/22.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page6/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/24.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E2/25.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/27.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page6/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/29.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page6/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/31.jpg', input: 5 },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/32.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/33.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page6/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/35.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E2/36.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/37.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page6/E2/38.jpg' },
      ]

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P6-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page6/E3/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page6/E3/07.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/08.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/09.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/10.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page6/E3/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/13.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page6/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page6/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/17.jpg', input: 3 },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/19.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/21.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page6/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/23.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/24.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P6-E4',
    audio: '',
    video: '',
    inputHeight: 33,
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page6/E4/Key/answerKey.png',
    component: D1,
    //titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page6/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page6/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E4/3.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page6/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page6/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page6/E4/6.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page6/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page6/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page6/E4/9.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page6/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page6/E4/11.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page6/E4/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page6/E4/13.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },



}

export default json;