import UI from '../../components/ExcerciseTypes/UseIt';
import L1 from '../../components/ExcerciseTypes/Design/Listen'
const json = {
  1: { // Exercise num
    unit : 'Unit 1',
    id :  'WB2-U1-P10-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page10/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit : 'Unit 1',
    id :  'WB2-U1-P10-E2',
    audio: '',
    video: '',
    component: L1,
    titleImage: '',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page10/E1/titleImage.jpg',
          'img/FriendsPlus/Page10/E1/U1P4E1-04.jpg',
          'img/FriendsPlus/Page10/E1/U1P4E1-05.jpg',
          'img/FriendsPlus/Page10/E1/U1P4E1-07.jpg',
          'img/FriendsPlus/Page10/E1/U1P4E1-06.jpg',

        ],
        audioUrl: [
          '',
          'img/FriendsPlus/Page10/E1/Audio/2.mp3',
          'img/FriendsPlus/Page10/E1/Audio/3.mp3',
          'img/FriendsPlus/Page10/E1/Audio/5.mp3',
          'img/FriendsPlus/Page10/E1/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;