
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit : 'Unit 6',
        id :  'WB2-U6-P47-E1',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page47/E1/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page47/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page47/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page47/E1/3.jpg' , input: true, answer: "living room" },
                { url: 'img/FriendsPlus/Page47/E1/4.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page47/E1/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page47/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page47/E1/7.jpg' , input: true, answer: "dining room" },
                { url: 'img/FriendsPlus/Page47/E1/8.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page47/E1/9.jpg' },
            ],
        ],
    },


    2: { // Exercise num
        unit : 'Unit 6',
        id :  'WB2-U6-P47-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page47/E2/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page47/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page47/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page47/E2/3.jpg' , input: true, answer: "Where's" },
                { url: 'img/FriendsPlus/Page47/E2/4.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page47/E2/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page47/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page47/E2/7.jpg' , input: true, answer: "He's" },
                { url: 'img/FriendsPlus/Page47/E2/8.jpg'},
            ],
            [
                { url: 'img/FriendsPlus/Page47/E2/9.jpg' },
            ],
        ],
    },
}

export default json;