import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P38-E1',
    audio: '',
    video: '',

    component: UnderLine,
    // component: DesignUnderLine1,
    totalInput: 4,

    exerciseKey: 'img/FriendsPlus/Page38/E1/Key/answerKeyP38E1.jpg',

    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page38/E1/1.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page38/E1/2.1.jpg', audioUrl: 'img/FriendsPlus/Page38/E1/Audio/queen.mp3' },
        { url: 'img/FriendsPlus/Page38/E1/2.2.jpg', audioUrl: 'img/FriendsPlus/Page38/E1/Audio/pen.mp3' },
        { url: 'img/FriendsPlus/Page38/E1/2.3.jpg', audioUrl: 'img/FriendsPlus/Page38/E1/Audio/nurse.mp3' },
        { url: 'img/FriendsPlus/Page38/E1/2.4.jpg', audioUrl: 'img/FriendsPlus/Page38/E1/Audio/orange.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page38/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page38/E1/4.jpg', input: 1, isCorrect: true, },
        { url: 'img/FriendsPlus/Page38/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/6.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page38/E1/7.jpg', input: 2, isCorrect: true, },
        { url: 'img/FriendsPlus/Page38/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/9.jpg', input: 3, isCorrect: true, },
        { url: 'img/FriendsPlus/Page38/E1/10.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page38/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/12.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page38/E1/13.jpg', input: 4, isCorrect: true, },
        { url: 'img/FriendsPlus/Page38/E1/14.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P38-E2',
    audio: '',
    video: '',
    typeInput: 'center',
    // typeInput1:'center',
    component: D1,
    inputHeight: '100%',
    fontSize: 35,
    exerciseKey: 'img/FriendsPlus/Page38/E2/Key/answerKeyP38E2.jpg',

    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page38/E2/1.jpg' },

      ],

      [
        { url: 'img/FriendsPlus/Page38/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page38/E2/Audio/lollipop.mp3' },
        { url: 'img/FriendsPlus/Page38/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page38/E2/Audio/lion.mp3' },
        { url: 'img/FriendsPlus/Page38/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page38/E2/Audio/orange.mp3' },
        { url: 'img/FriendsPlus/Page38/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page38/E2/Audio/queen.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page38/E2/6.jpg', },
        { url: 'img/FriendsPlus/Page38/E2/7.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page38/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/9.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page38/E2/10.jpg', },
        { url: 'img/FriendsPlus/Page38/E2/11.jpg', input: true, answer: 'q' },
        { url: 'img/FriendsPlus/Page38/E2/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page38/E2/13.jpg' },

      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P38-E3',
    audio: '',
    video: '',
    typeInput: 'center',
    component: D1,
    inputHeight: '100%',

    fontSize: 35,
    exerciseKey: 'img/FriendsPlus/Page38/E3/Key/answerKeyP38E3.jpg',

    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page38/E3/1.jpg' },

      ],

      [
        { url: 'img/FriendsPlus/Page38/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page38/E3/3.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page38/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page38/E3/5.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page38/E3/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page38/E3/7.jpg' },

      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P38-E4',
    audio: '',
    video: '',
    typeInput: 'center',
    component: D1,
    inputHeight: '100%',

    fontSize: 35,
    exerciseKey: 'img/FriendsPlus/Page38/E4/Key/answerKeyP38E4.jpg',

    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page38/E4/1.jpg' },

      ],

      [
        { url: 'img/FriendsPlus/Page38/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/3.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page38/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/5.jpg', input: true, answer: 'C' },
        { url: 'img/FriendsPlus/Page38/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/7.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page38/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/9.jpg', input: true, answer: 'E' },
        { url: 'img/FriendsPlus/Page38/E4/10.jpg', },
        { url: 'img/FriendsPlus/Page38/E4/11.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page38/E4/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page38/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/14.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page38/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/16.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page38/E4/17.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page38/E4/18.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page38/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/20.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page38/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/22.jpg', input: true, answer: 'J' },
        { url: 'img/FriendsPlus/Page38/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/24.jpg', input: true, answer: 'K' },
        { url: 'img/FriendsPlus/Page38/E4/25.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/26.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page38/E4/27.jpg', },
        { url: 'img/FriendsPlus/Page38/E4/28.jpg', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page38/E4/29.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/30.jpg', input: true, answer: 'N' },
        { url: 'img/FriendsPlus/Page38/E4/31.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/32.jpg', input: true, answer: 'O' },
        { url: 'img/FriendsPlus/Page38/E4/33.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/34.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page38/E4/35.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/36.jpg', input: true, answer: 'Q' },
        { url: 'img/FriendsPlus/Page38/E4/37.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page38/E4/38.jpg' },

      ],

    ],
  },

}
export default json;