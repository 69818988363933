import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P27-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page27/E1/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page27/E1/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page27/E1/1.jpg' },
      ],
    ]
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P27-E2',
    audio: 'img/FriendsPlus/Page27/E2/Audio/Track 010.mp3',
    video: '',
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page27/E2/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page27/E2/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page27/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page27/E2/07.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/08.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page27/E2/09.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/10.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page27/E2/11.jpg' },
      ],

      [
        { url: 'img/FriendsPlus/Page27/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page27/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page27/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page27/E2/17.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page27/E2/18.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;