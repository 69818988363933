import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Culture 1',
    id: 'WB2-C-P52-E1',
    audio: '',
    video: '',
    component: UnderLine,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page52/E1/Key/Key.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page52/E1/1.jpg' },

      ],

    ],
  },
  2: { // Exercise num
    unit: 'Culture 1',
    id: 'WB2-C-P52-E2',
    audio: '',
    video: '',

    component: UnderLine,
    hideBtnFooter: true,
    isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page6/E2/Key/1.PNG',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page52/E2/1.jpg' },

      ],

    ],
  },
}
export default json;