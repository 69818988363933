import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Starter',
    // audio: 'img/FriendsPlus/Page4/E1/Audio/sing.e2.p11.mp3',
    id: 'WB2-S-P4-E1',
    video: '',
    component: LT2,
    exerciseKey: 'img/FriendsPlus/Page4/E1/Key/Key.png',
    recorder: true,
    toAnchor: '50% -1%',
    fromAnchor: '50% 100%',
    // titleImage: 'img/FriendsPlus/Page4/E1/1.jpg',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page4/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/3.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page4/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/5.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page4/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/7.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page4/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/9.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page4/E1/10.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/13.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page4/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/15.jpg', isMatching: true, id: 6 },
        { url: 'img/FriendsPlus/Page4/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/17.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page4/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/19.jpg', isMatching: true, id: 8 },
        { url: 'img/FriendsPlus/Page4/E1/20.jpg' },
      ],
    ],
    answers: ['1-7', '2-5', '3-8', '4-6'],
  },

  2: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P4-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    // component: DesignUnderLine1,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page4/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page4/E2/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E2/05.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page4/E2/07.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page4/E2/08.jpg', input: 1, isCorrect: true },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E2/09.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page4/E2/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page4/E2/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page4/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page4/E2/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page4/E2/17.jpg', input: 3 },

      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P4-E3',
    audio: '',
    video: '',
    component: D1,
    inputHeight: '100%',
    isAllowSubmit: true,
    recorder: true,
    // exerciseKey: 'img/FriendsPlus/Page4/E3/Key/Key.PNG',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page4/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page4/E3/Audio/brown.mp3' },
        { url: 'img/FriendsPlus/Page4/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page4/E3/Audio/pink.mp3' },
      ],
      [
        { url: 'img/FriendsPlus/Page4/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page4/E3/Audio/orange.mp3' },
        { url: 'img/FriendsPlus/Page4/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page4/E3/Audio/purple.mp3' },
      ],
    ],
  },
}
export default json