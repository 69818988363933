import UI from '../../components/ExcerciseTypes/UseIt';
import L3 from '../../components/ExcerciseTypes/Design/Listen3'

const json = {
  1: { // Exercise num
    unit : 'Unit 2',
    id :  'WB2-U2-P21-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: 'img/FriendsPlus/Page21/E1/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page21/E1/1.jpg' },
      ],
    ]
  },

  2: { // Exercise num
    unit : 'Unit 2',
    id :  'WB2-U2-P21-E2',
    audio: '',
    video: '',
    component: L3,
    titleImage: 'img/FriendsPlus/Page21/E2/title.jpg',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page21/E2/title.jpg',
          'img/FriendsPlus/Page21/E2/1.jpg',
          'img/FriendsPlus/Page21/E2/2.jpg',
          'img/FriendsPlus/Page21/E2/3.jpg',
          'img/FriendsPlus/Page21/E2/4.jpg',
          'img/FriendsPlus/Page21/E2/5.jpg',
          'img/FriendsPlus/Page21/E2/6.jpg',

        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page21/E2/Audio/1.mp3',
          'img/FriendsPlus/Page21/E2/Audio/2.mp3',
          'img/FriendsPlus/Page21/E2/Audio/3.mp3',
          'img/FriendsPlus/Page21/E2/Audio/4.mp3',
          'img/FriendsPlus/Page21/E2/Audio/5.mp3',
          'img/FriendsPlus/Page21/E2/Audio/6.mp3',
          // 'img/FriendsPlus/Page21/E2/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;