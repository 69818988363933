import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import LT2 from '../../components/ExcerciseTypes/LineTo/LT2';

const json = {

  1: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P7-E1',
    // audio: 'img/FriendsPlus/Page7/E1/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT2,
    exerciseKey: 'img/FriendsPlus/Page7/E1/Key/answerKey.png',
    recorder: true,
    toAnchor: '50% -1%',
    fromAnchor: '50% 95%',
    // titleImage: 'img/FriendsPlus/Page7/E1/1.jpg',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page7/E1/3.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page7/E1/4.jpg', isMatching: true, id: 3 },
        { url: 'img/FriendsPlus/Page7/E1/5.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page7/E1/6.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page7/E1/7.jpg', isMatching: true, id: 6 },
        { url: 'img/FriendsPlus/Page7/E1/8.jpg', isMatching: true, id: 7 },
        { url: 'img/FriendsPlus/Page7/E1/9.jpg', isMatching: true, id: 8 },
        { url: 'img/FriendsPlus/Page7/E1/10.jpg', isMatching: true, id: 9 },
        { url: 'img/FriendsPlus/Page7/E1/11.jpg', isMatching: true, id: 10 },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E1/13.jpg', isMatching: true, id: 11 },
        { url: 'img/FriendsPlus/Page7/E1/14.jpg', isMatching: true, id: 12 },
        { url: 'img/FriendsPlus/Page7/E1/15.jpg', isMatching: true, id: 13 },
        { url: 'img/FriendsPlus/Page7/E1/16.jpg', isMatching: true, id: 14 },
        { url: 'img/FriendsPlus/Page7/E1/17.jpg', isMatching: true, id: 15 },
        { url: 'img/FriendsPlus/Page7/E1/18.jpg', isMatching: true, id: 16 },
        { url: 'img/FriendsPlus/Page7/E1/19.jpg', isMatching: true, id: 17 },
        { url: 'img/FriendsPlus/Page7/E1/20.jpg', isMatching: true, id: 18 },
        { url: 'img/FriendsPlus/Page7/E1/21.jpg', isMatching: true, id: 19 },
        { url: 'img/FriendsPlus/Page7/E1/22.jpg', isMatching: true, id: 20 },
      ],
    ],
    answers: ['1-14', '2-17', '3-11', '4-20', '5-13', '6-12', '7-18', '8-16', '9-19', '10-15',],
  },

  2: { // Exercise num
    unit: 'Starter',
    id: 'WB2-S-P7-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page7/E2/Key/Key.png',
    component: D1,
    fontSize: 30,
    inputHeight: '100%', titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Write the number', color: "#203c8e" }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/3.jpg', input: true, answer: "10" },
        { url: 'img/FriendsPlus/Page7/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/5.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page7/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/7.jpg', input: true, answer: "8" },
        { url: 'img/FriendsPlus/Page7/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/9.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page7/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/13.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page7/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/15.jpg', input: true, answer: "9" },
        { url: 'img/FriendsPlus/Page7/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/17.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page7/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/19.jpg', input: true, answer: "1" },
        { url: 'img/FriendsPlus/Page7/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/21.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page7/E2/22.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Starter',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/Key.png',
    component: D1,
    fontSize: 30,
    inputHeight: '100%', titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Write the number', color: "#203c8e" ],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/3.jpg', input: true, answer: "four" },
        { url: 'img/FriendsPlus/Page7/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/5.jpg', input: true, answer: "five" },
        { url: 'img/FriendsPlus/Page7/E3/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/9.jpg', input: true, answer: "seven" },
        { url: 'img/FriendsPlus/Page7/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/11.jpg', input: true, answer: "eight" },
        { url: 'img/FriendsPlus/Page7/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/13.jpg', input: true, answer: "ten" },
        { url: 'img/FriendsPlus/Page7/E3/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/15.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;