import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P41-E1',
    audio: '',
    video: '',
    // isAllowSubmit: true,
    component: UnderLine,
    // component: DesignUnderLine1,
    totalInput: 2,

    exerciseKey: 'img/FriendsPlus/Page41/E1/Key/answerKeyP41E1.jpg',
    recorder: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page41/E1/1.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/5.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/6.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/8.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page41/E1/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page41/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/11.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page41/E1/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page41/E1/15.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page41/E1/16.jpg' },

      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P41-E2',
    audio: '',
    video: '',
    component: D1,

    fontSize: 35,
    exerciseKey: 'img/FriendsPlus/Page41/E2/Key/answerKeyP41E2.jpg',
    inputHeight: '100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page41/E2/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/3.jpg', input: true, answer: 'yes' },
        { url: 'img/FriendsPlus/Page41/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E2/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/7.jpg', input: true, answer: 'no' },
        { url: 'img/FriendsPlus/Page41/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/9.jpg', input: true, answer: 'yes' },
        { url: 'img/FriendsPlus/Page41/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E2/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/13.jpg', input: true, answer: 'yes' },
        { url: 'img/FriendsPlus/Page41/E2/14.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page41/E2/15.jpg' },
      ],

    ]
  },
}
export default json;