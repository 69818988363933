import React from 'react'

const TitleQuestion = ({ titleQuestion }) => {
  const star = titleQuestion?.[0]?.star;
  const renderStar = React.useCallback(() => {

    return Array(star).fill('').map((item, index) => <i key={index} class="far fa-star" style={{ color: 'white', padding: 5 }} />)
  }, [star])


  if (!titleQuestion) return null;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
      <span
        style={{
          fontSize: 25,
          marginBottom: 10,
          fontWeight: '600',
          color: titleQuestion[0].color ?? '#33B1D6',
        }}>
        {titleQuestion[0].num}
      </span>
      <div style={{ color: '#231F20', fontSize: 25, fontWeight: '600', marginLeft: 10, minWidth: 200, display: 'flex', flexDirection: 'row' }}>
        {star && (
          <div style={{ backgroundColor: titleQuestion[0].color ?? '#33B1D6', borderRadius: 10, display: 'flex', justifyContent: 'start', alignItems: 'center', height: 35, width: 120 }}>
            {renderStar()}
          </div>
        )}

        <div className='ml-2'>

          {titleQuestion[0].title}
        </div>
      </div>
    </div>
  )
}

export default TitleQuestion;