import UI from '../../components/ExcerciseTypes/UseIt';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import L3 from '../../components/ExcerciseTypes/Design/Listen3'

const json = {
  1: { // Exercise num
    unit : 'Starter',
    id: 'WB2-S-P5-E1',
    audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page5/E1/Key/answerKey.png',
    recorder: true,
    fromAnchor: '100% 50%',
    toAnchor: '0% 50%',
    titleImage: 'img/FriendsPlus/Page5/E1/03.jpg',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page5/E1/05.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page5/E1/08.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page5/E1/11.jpg', isMatching: true, id: 3 },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/06.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/09.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/07.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page5/E1/10.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page5/E1/13.jpg', isMatching: true, id: 6 },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/14.jpg' },
      ],
    ],
    answers: ['1-5', '2-6', '3-4'],
  },
  2: { // Exercise num
    unit : 'Starter',
    id: 'WB2-S-P5-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: 'img/FriendsPlus/Page5/E2/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page5/E2/1.jpg' },
      ],
    ]
  },

  3: { // Exercise num
    unit : 'Starter',
    audio: '',
    video: '',
    component: L3,
    titleImage: 'img/FriendsPlus/Page5/E3/title.jpg',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page5/E3/title.jpg',
          'img/FriendsPlus/Page5/E3/1.jpg',
          'img/FriendsPlus/Page5/E3/2.jpg',
          'img/FriendsPlus/Page5/E3/3.jpg',
          'img/FriendsPlus/Page5/E3/4.jpg',

        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page5/E3/Audio/1.mp3',
          'img/FriendsPlus/Page5/E3/Audio/2.mp3',
          'img/FriendsPlus/Page5/E3/Audio/3.mp3',
          'img/FriendsPlus/Page5/E3/Audio/4.mp3',
          // 'img/FriendsPlus/Page5/E2/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;