
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
    1: { // Exercise num
        unit : 'Unit 6',
        id :  'WB2-U6-P43-E1',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page43/E1/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page43/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/3.jpg', input: true, answer: "living room" },
                { url: 'img/FriendsPlus/Page43/E1/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/7.jpg', input: true, answer: "Where's" },
                { url: 'img/FriendsPlus/Page43/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/9.jpg' , input: true, answer: "dining room"},
                { url: 'img/FriendsPlus/Page43/E1/10.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/11.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/12.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/13.jpg', input: true, answer: "Where's" },
                { url: 'img/FriendsPlus/Page43/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/15.jpg' , input: true, answer: "kitchen"},
                { url: 'img/FriendsPlus/Page43/E1/16.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/17.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/18.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/19.jpg', input: true, answer: "Where's" },
                { url: 'img/FriendsPlus/Page43/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page43/E1/21.jpg' , input: true, answer: "bedroom"},
                { url: 'img/FriendsPlus/Page43/E1/22.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page43/E1/23.jpg' },
            ],
        ],
    },

    2: { // Exercise num
        unit : 'Unit 6',
        id :  'WB2-U6-P43-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        // exerciseKey: 'img/FriendsPlus/Page43/E2/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page43/E2/1.jpg' },
            ],
        ],
    },

}

export default json;