import LT3 from '../../components/ExcerciseTypes/LineTo/LT3';
import UI from '../../components/ExcerciseTypes/UseIt';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P26-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: 'img/FriendsPlus/Page26/E1/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page26/E1/1.jpg' },
      ],
    ]
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P26-E2',
    audio: '',
    video: '',
    component: UI,
    titleImage: 'img/FriendsPlus/Page26/E2/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page26/E2/1.jpg' },
      ],
    ]
  },

  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P26-E3',
    audio: 'img/FriendsPlus/Page26/E3/Audio/audio.e3.p26.mp3',
    video: '',
    inputHeight: 35,
    fontSize: 35,
    typeInput: 'center',
    exerciseKey: 'img/FriendsPlus/Page26/E3/Key/answerKey.png',
    component: D1,
    //titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page26/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/3.jpg', input: true, answer: "v" },
        { url: 'img/FriendsPlus/Page26/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page26/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page26/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/7.jpg', input: true, answer: "v" },
        { url: 'img/FriendsPlus/Page26/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page26/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/10.jpg', input: true, answer: "v" },
        { url: 'img/FriendsPlus/Page26/E3/11.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'WB2-U3-P26-E4',
    audio: "img/FriendsPlus/Page26/E4/Audio/audio.e4.p26.mp3",
    video: '',
    component: LT3,
    numberAnser: 3,
    exerciseKey: 'img/FriendsPlus/Page26/E4/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '4', title: 'Listen and draw a line.' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "/img/FriendsPlus/Page26/E4/1.jpg",
          },
          {
            id: 2,
            text: "/img/FriendsPlus/Page26/E4/5.jpg",
          },
          {
            id: 3,
            text: "/img/FriendsPlus/Page26/E4/2.jpg",
          },
          {
            id: 4,
            text: "/img/FriendsPlus/Page26/E4/6.jpg",
          },
          {
            id: 5,
            text: "/img/FriendsPlus/Page26/E4/3.jpg",
          },
          {
            id: 6,
            text: "/img/FriendsPlus/Page26/E4/7.jpg",
          },
        ],
        left: [
          {
            id: 1,
            text: "/img/FriendsPlus/Page26/E4/4.jpg",
          },
        ],
        answer: [
          {
            right: 1,
            left: 1,
          },
          {
            right: 3,
            left: 1,
          },
          {
            right: 6,
            left: 1,
          },
        ],
      },
    ]
  },
}

export default json;