import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit: 'Unit 6',
        id: 'WB2-U6-P49-E1',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page49/E1/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page49/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page49/E1/3.jpg', input: true, answer: "She's" },
                { url: 'img/FriendsPlus/Page49/E1/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E1/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page49/E1/7.jpg', input: true, answer: "Where's" },
                { url: 'img/FriendsPlus/Page49/E1/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E1/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page49/E1/11.jpg', input: true, answer: "Is" },
                { url: 'img/FriendsPlus/Page49/E1/12.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E1/13.jpg' },
            ],
        ],
    },

    2: { // Exercise num
        unit: 'Unit 6',
        id: 'WB2-U6-P49-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        typeInput: 'center',
        exerciseKey: 'img/FriendsPlus/Page49/E2/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page49/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page49/E2/3.jpg', input: true, answer: "x" },
                { url: 'img/FriendsPlus/Page49/E2/4.jpg' },
                { url: 'img/FriendsPlus/Page49/E2/5.jpg', input: true, answer: "y" },
                { url: 'img/FriendsPlus/Page49/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page49/E2/7.jpg', input: true, answer: "w" },
                { url: 'img/FriendsPlus/Page49/E2/8.jpg' },
                { url: 'img/FriendsPlus/Page49/E2/9.jpg', input: true, answer: "z", },
                { url: 'img/FriendsPlus/Page49/E2/10.jpg', input: true, answer: "z" },
                { url: 'img/FriendsPlus/Page49/E2/11.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E2/12.jpg' },
            ],
        ],
    },


    3: { // Exercise num
        unit: 'Unit 6',
        id: 'WB2-U6-P49-E3',
        audio: '',
        video: '',
        component: UnderLine,
        hideBtnFooter: true,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: true,
        typeInput: 'center',
        // exerciseKey: 'img/FriendsPlus/Page49/E3/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page49/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page49/E3/3.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page49/E3/4.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page49/E3/5.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page49/E3/6.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page49/E3/7.jpg' },
            ],
        ],
    },
}

export default json;