
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
         unit : 'Unit 5',
        id :  'WB2-U5-P37-E1',
        audio: '',
        video: '',
        component: LT1,
        // exerciseKey: 'img/FriendsPlus/Page11/E3/Key/answerKeyP11E3.jpg',
        recorder: true,
        toAnchor: '0% 50%',
        fromAnchor: '100% 50%',
        titleImage: 'img/FriendsPlus/Page37/E1/1.jpg',
        questionImage: [ // Row
            // [
            //   { url: 'img/FriendsPlus/Page37/E1/1.jpg' },
            // ],
            [
                { url: 'img/FriendsPlus/Page37/E1/2.jpg', isMatching: true, id: 1 },
                { url: 'img/FriendsPlus/Page37/E1/5.jpg', isMatching: true, id: 2 },
                { url: 'img/FriendsPlus/Page37/E1/8.jpg', isMatching: true, id: 3 },
            ],
            [
                { url: 'img/FriendsPlus/Page37/E1/3.jpg' },
                { url: 'img/FriendsPlus/Page37/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page37/E1/9.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page37/E1/4.jpg', isMatching: true, id: 4 },
                { url: 'img/FriendsPlus/Page37/E1/7.jpg', isMatching: true, id: 5 },
                { url: 'img/FriendsPlus/Page37/E1/10.jpg', isMatching: true, id: 6 },
            ],
        ],
        answers: ['1-6', '2-4', '3-5'],
    },



//match three column

    // 1: { // Exercise num
     unit : 'Unit 5',
    //     audio: '',
    //     video: '',
    //     component: LT4,
    //     exerciseKey: 'img/FriendsPlus/Page37/E1_1/Key/answerKeyP40E1.jpg',
    //     // recorder: true,
    //     fromAnchor:'100% 50%',
    //     isColumn :'column',
    //     toAnchor:'0% 50%',
    //     titleImage: 'img/FriendsPlus/Page37/E1_1/1.jpg',
    //     questionImage: [ // Row
      
    //       [
    //         // Column2
    //         { url: 'img/FriendsPlus/Page37/E1_1/2.jpg',isMatching: true, id: 1 },
    //         { url: 'img/FriendsPlus/Page37/E1_1/7.jpg',isMatching: true, id: 2 },
    //         { url: 'img/FriendsPlus/Page37/E1_1/12.jpg',isMatching: true, id: 3 },
           
           
    //       ],
    //       [
    //         // Column2
    //         { url: 'img/FriendsPlus/Page37/E1_1/3.jpg' },
    //         { url: 'img/FriendsPlus/Page37/E1_1/8.jpg' },
    //         { url: 'img/FriendsPlus/Page37/E1_1/13.jpg' },
           
           
    //       ],

    //       [
    //         // Column2
    //         { url: 'img/FriendsPlus/Page37/E1_1/4.jpg',isMatching: true, id: 4 },
    //         { url: 'img/FriendsPlus/Page37/E1_1/9.jpg',isMatching: true, id: 5 },
    //         { url: 'img/FriendsPlus/Page37/E1_1/14.jpg',isMatching: true, id: 6 },

    //       ],
    //       [
    //         // Column2
    //         { url: 'img/FriendsPlus/Page37/E1_1/5.jpg' },
    //         { url: 'img/FriendsPlus/Page37/E1_1/10.jpg' },
    //         { url: 'img/FriendsPlus/Page37/E1_1/15.jpg' },
           
           
    //       ],
    //       [
    //         // Column2
    //         { url: 'img/FriendsPlus/Page37/E1_1/6.jpg',isMatching: true, id: 7 },
    //         { url: 'img/FriendsPlus/Page37/E1_1/11.jpg',isMatching: true, id: 8 },
    //         { url: 'img/FriendsPlus/Page37/E1_1/16.jpg',isMatching: true, id: 9 },

    //       ],
        
    //     ],

       
    //     answers: ['3-4','4-9','1-5','5-7','2-6','6-8'],
    //     // answers: ['1-5-9','2-6-10','3-8-11','4-7-12'],
    //   },






    2: { // Exercise num
         unit : 'Unit 5',
        id :  'WB2-U5-P37-E2',
        audio: '',
        video: '',
        fontSize: 30,
        inputHeight: '100%',
        exerciseKey: 'img/FriendsPlus/Page37/E2/Key/answerKey.png',
        component: D1,
        //titleImage: '',
        //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page37/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page37/E2/3.jpg', input: true, answer: "He's in the goal." },
                { url: 'img/FriendsPlus/Page37/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page37/E2/5.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page37/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page37/E2/7.jpg', input: true, answer: "He's on the slide." },
                { url: 'img/FriendsPlus/Page37/E2/8.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page37/E2/9.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

}

export default json;