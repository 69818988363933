
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
 
 
  1: { // Exercise num
    unit : 'Culture 4',
    id :  'WB2-C-P55-E1',
    audio: '',
    video: '',
    component: D1,
    
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page55/E1/Key/answerKeyP55E1.jpg',
    inputHeight:'100%',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page55/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page55/E1/3.jpg' ,input: true,answer:'kite'},
        { url: 'img/FriendsPlus/Page55/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/5.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page55/E1/7.jpg',input: true,answer:'board game' },
        { url: 'img/FriendsPlus/Page55/E1/8.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/9.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page55/E1/11.jpg',input: true,answer:'doll' },
        { url: 'img/FriendsPlus/Page55/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page55/E1/13.jpg' },
      ],
    
    ]
    } ,
    2: { // Exercise num
      unit : 'Culture 4',
      id :  'WB2-C-P55-E2',
        audio: '',
        video: '',
        component: D1,
   
        fontSize: 30,
        exerciseKey: 'img/FriendsPlus/Page55/E2/Key/answerKeyP55E2.jpg',
        inputHeight:'100%',
        questionImage: [ // Row
          [
            { url: 'img/FriendsPlus/Page55/E2/1.jpg' },
          ],
          [
            { url: 'img/FriendsPlus/Page55/E2/2.jpg' },
            { url: 'img/FriendsPlus/Page55/E2/3.jpg' ,input: true,answer:'oll'},
            { url: 'img/FriendsPlus/Page55/E2/4.jpg' },
          ],
          [
            { url: 'img/FriendsPlus/Page55/E2/5.jpg' },
          ],
          [
            { url: 'img/FriendsPlus/Page55/E2/6.jpg' },
            { url: 'img/FriendsPlus/Page55/E2/7.jpg',input: true,answer:'oard' },
            { url: 'img/FriendsPlus/Page55/E2/8.jpg' },
          ],
          [

            { url: 'img/FriendsPlus/Page55/E2/9.jpg' },
          ],
          [
            { url: 'img/FriendsPlus/Page55/E2/10.jpg' },
            { url: 'img/FriendsPlus/Page55/E2/11.jpg',input: true,answer:'ame' },
            { url: 'img/FriendsPlus/Page55/E2/12.jpg' },
          ],
          [
            { url: 'img/FriendsPlus/Page55/E2/13.jpg' },
          ],
        
        ]
        } ,
}
export default json;