
import L1 from '../../components/ExcerciseTypes/Design/Listen'
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {
  1: { // Exercise num
    unit : 'Unit 2',
    id :  'WB2-U2-P16-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page16/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit : 'Unit 2',
    id :  'WB2-U2-P16-E2',
    audio: '',
    video: '',
    component: L1,
    titleImage: '',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page16/E2/title.jpg',
          'img/FriendsPlus/Page16/E2/1.jpg',
          'img/FriendsPlus/Page16/E2/2.jpg',
          'img/FriendsPlus/Page16/E2/3.jpg',
          'img/FriendsPlus/Page16/E2/4.jpg',

        ],
        audioUrl: [
          "",
          "img/FriendsPlus/Page16/E2/Audio/happy.mp3",
          "img/FriendsPlus/Page16/E2/Audio/hot.mp3",
          "img/FriendsPlus/Page16/E2/Audio/sad.mp3",
          "img/FriendsPlus/Page16/E2/Audio/hungry.mp3",
          
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;