import L2 from '../../components/ExcerciseTypes/Design/Listen2';
import UI from '../../components/ExcerciseTypes/UseIt';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1';


const json = {
  1: { // Exercise num
    unit : 'Unit 2',
    id :  'WB2-U2-P17-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page17/2.jpg' },
      ],
    ]
  },  
  2: { // Exercise num
    unit : 'Unit 2',
    id :  'WB2-U2-P17-E2',
    audio: '',
    video: '',
    component: L2,
    titleImage: '',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page17/E2/title.jpg',
          'img/FriendsPlus/Page17/E2/1.jpg',
          'img/FriendsPlus/Page17/E2/2.jpg',
          'img/FriendsPlus/Page17/E2/3.jpg',

        ],
        audioUrl: [
          null,
          "img/FriendsPlus/Page17/E2/Audio/He's happy..mp3",
          "img/FriendsPlus/Page17/E2/Audio/She's thirsty..mp3",
          "img/FriendsPlus/Page17/E2/Audio/He's cold..mp3",
          // 'img/FriendsPlus/Page17/E2/Audio/4.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
  3: { // Exercise num
    unit : 'Unit 2',
    id :  'WB2-U2-P17-E3',
    audio: 'img/FriendsPlus/Page11/E2/Audio/sing.e2.p11.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page17/E3/Key/answerKey.png',
    recorder: true,
    toAnchor: '10% 30%',
    fromAnchor: '93% 33%',
    titleImage: 'img/FriendsPlus/Page17/E3/1.jpg',
    questionImage: [ // Row
      // [
      //   { url: 'img/FriendsPlus/Page17/E3/1.jpg' },
      // ],
      [
        { url: 'img/FriendsPlus/Page17/E3/2.jpg', isMatching: true, id: 1 },
        { url: 'img/FriendsPlus/Page17/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/8.jpg', isMatching: true, id: 2 },
        { url: 'img/FriendsPlus/Page17/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/14.jpg', isMatching: true, id: 3 },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E3/4.jpg', isMatching: true, id: 4 },
        { url: 'img/FriendsPlus/Page17/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/10.jpg', isMatching: true, id: 5 },
        { url: 'img/FriendsPlus/Page17/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/16.jpg', isMatching: true, id: 6 },
      ],
    ],
    answers: ['1-6', '2-4', '3-5'],
  },
}

export default json;