import UI from '../../components/ExcerciseTypes/UseIt';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';



const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P33-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page33/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P33-E2',
    audio: '',
    video: '',
    inputHeight: 33,
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page33/E2/Key/1.PNG',
    component: D1,
    // typeInput: 'center',
    //titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Count and write the number', color: "#253E8E" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/3.jpg', input: true, answer: "18" },
        { url: 'img/FriendsPlus/Page33/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page33/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page33/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/7.jpg', input: true, answer: "17" },
        { url: 'img/FriendsPlus/Page33/E2/8.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'WB2-U4-P33-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page33/E3/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page33/E3/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page33/E3/05.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page33/E3/07.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/08.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page33/E3/09.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/10.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page33/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/12.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page33/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/14.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page33/E3/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page33/E3/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page33/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/18.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page33/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/20.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page33/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/22.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page33/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/24.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page33/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page33/E3/26.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page33/E3/27.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;