import UI from '../../components/ExcerciseTypes/UseIt';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";



const json = {

  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P39-E1',
    audio: '',
    video: '',
    component: UI,
    titleImage: 'img/FriendsPlus/Page39/E1/title.jpg',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page39/E1/1.jpg' },
      ],
    ]
  },

  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P39-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E2/Key/Key.png',
    inputSize: 200,
    // titleImage: 'img/FriendsPlus/Page39/E2/title.jpg',
    titleQuestion: [{ num: '2', title: 'Write the word. ', color: "#254595" }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span>19 # </span><span> 20 # </span></p>",
        answer: [
          "nineteen", "twenty",


        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P39-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E3/Key/Key.png',
    inputSize: 70,
    // titleImage: 'img/FriendsPlus/Page39/E2/title.jpg',
    titleQuestion: [{ num: '3', title: 'Write the missing number  . ', color: "#254595" }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> 1 # 3 &ensp;&ensp;4 # # 7 # 9 #</p>" +
          "<p> 11 # # 14 # 16 # # 19 #</p>",
        answer: [
          "2", "5", "6", "8", "10",
          "12", "13", "15", "17", "18", "20",


        ],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 5',
    id: 'WB2-U5-P39-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page39/E4/Key/answerKey.png',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page39/E4/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E4/05.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/06.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page39/E4/07.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/08.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page39/E4/09.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/10.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page39/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/12.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page39/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/14.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page39/E4/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E4/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page39/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/18.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page39/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/20.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page39/E4/21.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/22.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page39/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/24.jpg', input: 9, isCorrect: true },
        { url: 'img/FriendsPlus/Page39/E4/25.jpg' },
        { url: 'img/FriendsPlus/Page39/E4/26.jpg', input: 10, isCorrect: true },
        { url: 'img/FriendsPlus/Page39/E4/27.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;