
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    1: { // Exercise num
        unit : 'Unit 6',
        id :  'WB2-U6-P48-E1',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page48/E1/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page48/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page48/E1/3.jpg' , input: true, answer: "John" },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E1/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page48/E1/6.jpg' , input: true, answer: "Amy" },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E1/7.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page48/E1/9.jpg' , input: true, answer: "Lisa" },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E1/10.jpg' },
            ],
        ],
    },

    2: { // Exercise num
        unit : 'Unit 6',
        id :  'WB2-U6-P48-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page48/E2/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page48/E2/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page48/E2/3.jpg' , input: true, answer: "walk to school" },
                { url: 'img/FriendsPlus/Page48/E2/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E2/5.jpg' },
            ],
        ],
    },

    3: { // Exercise num
        unit : 'Unit 6',
        id :  'WB2-U6-P48-E3',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        inputHeight: '100%',
        isAllowSubmit: false,
        exerciseKey: 'img/FriendsPlus/Page48/E3/Key/1.PNG',
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page48/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page48/E3/3.jpg' , input: true, answer: "bird" },
                { url: 'img/FriendsPlus/Page48/E3/4.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E3/5.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page48/E3/7.jpg', input: true, answer: "book"  },
                { url: 'img/FriendsPlus/Page48/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page48/E3/9.jpg'},
            ],
          
        ],
    },
}

export default json;