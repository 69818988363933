import UI from '../../components/ExcerciseTypes/UseIt';
import L3 from '../../components/ExcerciseTypes/Design/Listen3s'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit : 'Unit 1',
    id :  'WB2-U1-P15-E1',
    audio: 'img/FriendsPlus/Page15/Audio/Track 003.mp3',
    video: '',
    component: D1,
    titleImage: '',
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page15/2.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit : 'Unit 1',
    id :  'WB2-U1-P15-E2',
    audio: '',
    video: '',
    component: L3,
    titleImage: '',
    question: [
      {
        imgUrl: [
          'img/FriendsPlus/Page15/E2/titleImgE2.png',
          'img/FriendsPlus/Page15/E2/1.jpg',
          'img/FriendsPlus/Page15/E2/2.jpg',
          'img/FriendsPlus/Page15/E2/3.jpg',
        ],
        audioUrl: [
          null,
          'img/FriendsPlus/Page15/E2/Audio/1.mp3',
          'img/FriendsPlus/Page15/E2/Audio/2.mp3',
          'img/FriendsPlus/Page15/E2/Audio/3.mp3',
        ],
        isLongAudio: []
      },
    ],
    questionImage: []
  },
}

export default json;